import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './containers/App/App';
import WebSocketProvider from './shared/helpers/ws';
import ErrorLogger from './shared/helpers/errorLogger';
import { getEnvSettings } from './config/environmentSettings';
const settings = getEnvSettings();
const localWsUri = localStorage.getItem('WS_URL');
const wsUri = localWsUri ? localWsUri : settings.wsUri;
window.OrderBookWS = new WebSocketProvider(wsUri);
window.ErrorLogger = new ErrorLogger();
const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(App, null));
}
